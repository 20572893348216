#approach-page {

  .section__human-centric {
    position: relative;
    background-color: var(--accent-color);
    min-height: 520px;
    padding: 50px 0;

    .img-bg {
      position: absolute;
      top: 57px;
      left: 0;
      width: 50%;
      height: 420px;
      background-image: url("../../assets/images/image__human-centric.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .content {
      .head-top {
        margin-bottom: 50px;

        .head-sm {
          font-size: 12px;
        }
      }

      .text-block {
        background-color: var(--accent-white);
        color: var(--accent-color);
        padding: 40px 50px;
      }

    }
  }


  .section__experiential {
    position: relative;
    background-color: var(--accent-color);
    min-height: 730px;
    padding: 100px 0;

    .img-bg {
      position: absolute;
      top: 100px;
      right: 0;
      width: 50%;
      height: 530px;
      background-image: url("../../assets/images/image__experiential.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .content {
      .head-top {
        margin-bottom: 50px;

        .head-sm {
          font-size: 12px;
        }
      }

      .text-block {
        background-color: var(--accent-white);
        color: var(--accent-color);
        padding: 40px 50px;
      }
    }
  }


  .section__future-forward {
    position: relative;
    background-color: var(--accent-color);
    min-height: 520px;
    padding: 50px 0;

    .img-bg {
      position: absolute;
      top: 57px;
      left: 0;
      width: 50%;
      height: 420px;
      background-image: url("../../assets/images/image__future-forward.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .content {
      .head-top {
        margin-bottom: 50px;

        .head-sm {
          font-size: 12px;
        }
      }

      .text-block {
        background-color: var(--accent-white);
        color: var(--accent-color);
        padding: 40px 50px;
      }

    }
  }


  .section__collaborative {
    position: relative;
    background-color: var(--accent-color);
    min-height: 730px;
    padding: 100px 0;

    .img-bg {
      position: absolute;
      top: 100px;
      right: 0;
      width: 50%;
      height: 530px;
      background-image: url("../../assets/images/image__collaborative.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .content {
      .head-top {
        margin-bottom: 100px;

        .head-sm {
          font-size: 12px;
        }
      }

      .text-block {
        background-color: var(--accent-white);
        color: var(--accent-color);
        padding: 40px 50px;
      }
    }
  }


  .section__design-philosophy {
    position: relative;
    background-color: var(--accent-color);
    min-height: 860px;
    padding: 50px 0;

    .img-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 760px;
      background-image: url("../../assets/images/image__human-centric.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .content {
      .head-top {
        margin-bottom: 50px;

        .head-sm {
          font-size: 12px;
        }
      }

      .text-block {
        background-color: var(--accent-white);
        color: var(--accent-color);
        padding: 40px 50px;
      }

    }
  }

}