.our-destinations {
  padding: 80px 0;

  .video-play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10%;
  }

  .video__play {
    .video-play-icon {
      display: none;
    }
  }
}