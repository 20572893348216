.future-forward {
  padding: 60px 0;

  .future-forward-animation-wrapper {
    min-height: 180px;
    width: 100%;
    position: relative;

    .animation-item {
      position: absolute;
      top: 0;
      left: 0;
      color: #ffffff;
      font-size: 4.5vw;
      width: 100%;
      height: 100%;
      line-height: 0.85;
      font-weight: normal;
      text-transform: uppercase;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
    }

  }

}