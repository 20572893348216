#projects {

  .projects-slider {
    .swiper-slide {
      @media (min-width: 992px) {
        width: 25%;

        &.swiper-slide-active {
          width: 50%;
        }
      }

      .slider-content {
        .slide-img {
          height: 505px;
          width: 100%;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
        .details {
          padding: 24px 0;
          @media (max-width: 992px) {
            padding: 24px 16px;
          }

        }

      }

    }
  }
}